<template>
  <div class="container cl17 bgf fs14 flex flex-direction-column">
    <div class="container-header flex-s0">
      <div class="fs16 fw500 mb5">选择你客户的个性标签</div>
      <div class="text-color fs12">让你更方便了解客户，为你节省大量时间</div>
    </div>
    <div class="container-wrapper flex-1 flex">
      <div class="menu-wrapper flex-s0" ref="menuScroll">
        <div class="bgf">
          <van-sidebar v-model="activeMenu">
            <van-sidebar-item
              v-for="(item, index) in customerLabel"
              :key="index"
              :title="item.group_name"
              :class="{
                'menu-item-last': activeMenu - 1 === index,
                'menu-item-next': activeMenu + 1 === index
              }"
              :id="'menu' + index"
              @click="selectMenu(index)"
            />
          </van-sidebar>
        </div>
      </div>
      <div class="label-wrapper pbs flex-1" ref="labelScroll">
        <div
          v-for="(item, index) in customerLabel"
          :key="index"
          :id="'label-cell' + index"
          class="label-cell"
        >
          <p class="fw500 mb8 pt12">{{ item.group_name }}</p>
          <div class="flex flex-wrap">
            <div
              v-for="label in item.group_list"
              :key="label.id"
              class="label-item mr8 txt-line-1"
              @click="onHandle(label, index)"
            >
              <div
                class="label-item-title border-box fs12"
                :class="{ 'label-item-active': label.is_labeled != 2 }"
              >
                {{ label.label_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Toast } from 'vant'
import { nextTick, onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { getCurExternalContact } from '@/utils/nwechat.js'
import { getCustomerLabel, labelCustomers } from '@/service/customer.service.js'

const route = useRoute()
const userId = ref('') // 用户id
const activeMenu = ref(0) // 选中的标签类索引
const customerLabel = ref([]) // 客户标签
const menuScroll = ref(null) // 菜单栏ref
const labelScroll = ref(null) // 内容区域ref
const labelCellHeights = ref([]) // 标签Html高度
const loading = ref(false) // 事件loading

onMounted(() => {
  initData()
  initScroll()
})
// 添加labelScroll的scroll事件
const initScroll = () => {
  labelScroll.value.addEventListener('scroll', (e) => {
    const scrollY = labelScroll.value.scrollTop

    labelCellHeights.value.forEach((item, i) => {
      let height1 = labelCellHeights.value[i]
      let height2 = labelCellHeights.value[i + 1]
      let height3 =
        labelCellHeights.value[labelCellHeights.value.length - 1] -
        labelScroll.value.clientHeight
      if (scrollY >= height1 && scrollY < height2) {
        if (activeMenu.value < i || scrollY < height3) {
          if (activeMenu.value !== i) {
            document.getElementById('menu' + i).scrollIntoView(true)
          }
          activeMenu.value = i
        }
      }
    })
  })
}
// 获取label-cell标签的可视高度
const calulateHeight = () => {
  let foodlist = labelScroll.value.getElementsByClassName('label-cell')
  let height = 0
  const data = []
  data.push(height)
  for (let i = 0; i < foodlist.length; i++) {
    height += foodlist[i].clientHeight
    data.push(height)
  }
  //   foodlist.forEach((item) => {
  //     height += item.clientHeight
  //     data.push(height)
  //   })
  labelCellHeights.value = data
}
// 选择标签类, 滑动到该类的标签
const selectMenu = (i) => {
  activeMenu.value = i
  document.getElementById('label-cell' + i).scrollIntoView(true)
}
// 打标签
const onHandle = async (data = {}, groupIndex) => {
  try {
    const { id, marketing_suggestions, is_labeled } = data
    if (loading.value) return false

    loading.value = true
    Toast.loading({
      message: '加载中',
      forbidClick: true,
      duration: 0
    })
    const res = await labelCustomers({
      external_userid: userId.value,
      label_id: id,
      marketing_suggestions,
      status: is_labeled == 1 ? 2 : 1
    })
    loading.value = false
    Toast.clear()
    if (res.data && res.data.labeled == 'success') {
      data.is_labeled = is_labeled == 1 ? 2 : 1
      // 判断标签组是否是互斥
      const type = customerLabel.value[groupIndex]?.type
      if (type === 'MUTEX' && data.is_labeled == 1) {
        const length = customerLabel.value[groupIndex].group_list.length
        for (let i = 0; i < length; i++) {
          const item = customerLabel.value[groupIndex].group_list[i]
          if (item.id !== data.id) {
            customerLabel.value[groupIndex].group_list[i].is_labeled = 2
          }
        }
      }
    }
  } catch (error) {
    loading.value = false
    Toast.clear()
  }
}

// 初始化数据
const initData = async () => {
  try {
    let id = route.query.id
    if (!id) {
      id = await getCurExternalContact()
    }
    userId.value = id
    await getLabelList()

    nextTick(() => {
      calulateHeight()
    })
  } catch (error) {
    console.log(error)
  }
}
// 获取标签组列表
const getLabelList = async () => {
  try {
    Toast.loading({
      message: '加载中',
      forbidClick: true,
      duration: 0
    })
    const { data } = await getCustomerLabel(userId.value)
    Toast.clear()
    customerLabel.value = data
  } catch (error) {
    Toast.clear()
  }
}
</script>

<style lang="less" scoped>
@import './tag.less';
</style>
